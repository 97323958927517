<template>
  <v-card>
    <v-card-text>
      <v-progress-linear
        v-if="isReportByVehicleLoading"
        indeterminate
      />
      <highcharts
        v-if="show"
        :chart-data="chartData"
      />
      <v-alert
        v-else
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
      >{{ $t('chartByVehicleTab.warning') }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import Highcharts from '@/components/Highcharts.vue';

export default {
  name: 'ChartByVehicle',
  data: () => ({
    show: false,
  }),
  components: {
    Highcharts,
  },
  computed: {
    ...mapState(['reportByVehicle', 'search', 'isReportByVehicleLoading']),

    debounceRunSearchQuery() {
      return debounce(this.getItems, this.$store.getters.APITimeout);
    },
    chartData() {
      if (!this.reportByVehicle.length) {
        return {};
      }
      const medianPayNow = this.reportByVehicle.map(
        // eslint-disable-next-line camelcase
        ({ avg_pay_now }) => avg_pay_now || 0,
      ).sort()[Math.floor(this.reportByVehicle.length / 2)];
      const medianPayLater = this.reportByVehicle.map(
        // eslint-disable-next-line camelcase
        ({ avg_pay_later }) => avg_pay_later || 0,
      ).sort()[Math.floor(this.reportByVehicle.length / 2)];

      return {
        title: {
          text: this.$t('chartByVehicleTab.legend', {
            vehicle: this.search.vehicles[0],
            region: this.search.locations[0],
          }),
        },
        xAxis: {
          categories: this.reportByVehicle.map(({ source }) => source),
          title: { text: this.$t('chartByVehicleTab.xAxis') },
        },
        yAxis: {
          title: { text: this.$t('chartByVehicleTab.yAxis') },
          plotLines: [
            {
              color: 'blue',
              label: {
                text: this.$t('chartByVehicleTab.medianPayNow', { value: medianPayNow }),
                align: 'right',
                style: { fontWeight: 'bold' },
              },
              value: medianPayNow,
              dashStyle: 'LongDash',
              zIndex: 999,
            },
            {
              color: 'green',
              label: {
                text: this.$t('chartByVehicleTab.medianPayLater', { value: medianPayLater }),
                style: { fontWeight: 'bold' },
              },
              value: medianPayLater,
              dashStyle: 'LongDash',
              zIndex: 999,
            },
          ],
        },
        series: [
          {
            type: 'column',
            name: this.$t('chartByVehicleTab.averagePayNow'),
            // eslint-disable-next-line camelcase
            data: this.reportByVehicle.map(({ avg_pay_now }) => avg_pay_now || 0),
            color: '#BA68C8',
            marker: { radius: 0 },
          },
          {
            type: 'column',
            name: this.$t('chartByVehicleTab.averagePayLater'),
            // eslint-disable-next-line camelcase
            data: this.reportByVehicle.map(({ avg_pay_later }) => avg_pay_later || 0),
            color: '#EF5350',
            marker: { radius: 0 },
          },
        ],
      };
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.show = this.isSingleSelected();
      },
    },
  },
  mounted() {
    this.show = this.isSingleSelected();
    this.$root.$on('chart-by-vehicle', () => {
      this.debounceRunSearchQuery();
    });
    if (!this.reportByVehicle.length) {
      this.debounceRunSearchQuery();
    }
  },
  methods: {
    ...mapActions(['getReportByVehicle']),

    getItems() {
      if (!this.show) return;
      this.getReportByVehicle({ search: { ...this.search } });
    },
    isSingleSelected() {
      return this.search.vehicles.length === 1 && this.search.locations.length === 1;
    },
  },
};
</script>
